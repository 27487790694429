// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		margin: 2.5px 0;
		padding: 0 0 0 1.75rem;
		color: $primary;
		font-weight: 700;

		&::before {
			content: '\f138';
			position: absolute;
			top: 0;
			left: 0;
			color: $primary;
			font-weight: 300;
			font-family: $font-awesome;
		}

		a {
			text-decoration: none;
		}
	}
}
