// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;

	@include media-breakpoint-up(sm) {
		min-height: 400px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 70vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(lg) {
		height: 100vh;
		min-height: 400px;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::after {
		content: url("/images/eyecatcher-wave.svg");
		z-index: 90;
		position: absolute;
		left: -5px;
		right: -5px;
		bottom: -5px;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	// owl-carousel
	.owl-carousel {
		.item {
			&::before {
				display: none;
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 50%;
				background: linear-gradient(
					180deg,
					rgba($black, 0.5) 0%,
					rgba($black, 0) 100%
				);
			}

			.owl-caption-holder {
				top: auto;
				bottom: 50px;

				@include media-breakpoint-up(lg) {
					bottom: 150px;
				}

				.owl-container {
					max-width: 930px;

					.owl-caption {
						display: flex;
						flex-direction: column;
						margin: 0 15px;
						text-shadow: none;
						text-align: left;

						.owl-title {
							font-size: 48px;
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	// jump
	a.jump {
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 24px;
		height: 24px;
		margin: 0 auto;
		color: $blue-dark;
		font-size: 24px;
		line-height: 1;
		text-decoration: none;

		&::before {
			content: "\f0ab";
			font-weight: 300;
			font-family: $font-awesome;
		}

		&:hover {
			transform: translateY(5px);
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	// cta-badge
	.cta-badge {
		z-index: 100;
		position: absolute;
		top: 90px;
		right: 60px;

		@include media-breakpoint-down(sm) {
			right: 20px;
		}

		img {
			max-width: 180px;
		}

		// .grid-items {
		// 	margin: 0 !important;

		// 	.item {
		// 		flex: 100% !important;
		// 		max-width: unset !important;
		// 		margin: 0 !important;
		// 		padding: 0 !important;

		// 		.card {
		// 			display: flex;
		// 			flex-direction: row;
		// 			// background-color: $white;
		// 			// border: 4px solid $blue-dark;
		// 			border-radius: 15px;
		// 			overflow: visible;

		// 			background-color: transparent;

		// 			.card-image {
		// 				position: absolute !important;
		// 				// left: -45px;
		// 				left: -14rem;
		// 				top: -64px;

		// 				img {
		// 					max-width: 180px;
		// 					height: 170px;
		// 				}
		// 			}

		// 			.card-body {
		// 				display: none !important;
		// 				padding: 1px 20px 5px 120px;

		// 				.card-caption {
		// 					color: $blue-dark;

		// 					.card-title {
		// 						margin: 0;
		// 						font-size: 30px;
		// 						font-family: $font-family-tertiary;

		// 						@include media-breakpoint-down(sm) {
		// 							font-size: 28px;
		// 						}

		// 						&:hover {
		// 							color: inherit;
		// 						}
		// 					}

		// 					.card-subtitle {
		// 						display: inline-flex;
		// 						align-items: center;
		// 						margin: 0;
		// 						font-size: 14px;
		// 						font-weight: 700;
		// 						line-height: 1;
		// 						font-family: $font-family-primary;
		// 						color: $blue-dark;

		// 						&::before {
		// 							content: "\f138";
		// 							font-family: $font-awesome;
		// 							margin-right: 10px;
		// 							font-size: 18px;
		// 							font-weight: 300;
		// 							transition: 0.5s;
		// 						}
		// 					}

		// 					.card-description {
		// 						display: none;
		// 					}
		// 				}
		// 			}

		// 			&:hover {
		// 				.card-subtitle {
		// 					&::before {
		// 						transform: translateX(5px);
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

// video banner vimeo
section.eyecatcher.large:has(.video-eyecatcher) {
@include media-breakpoint-down(sm) {
height: auto;
}
	.video-eyecatcher {
		padding: 0;
		width: 100vw;
		height: 100%;
		position: relative;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
			transform: scale(116%);
			@include media-breakpoint-down(sm) {
				height: 36vh;
			}
		}
	}
}
