.elfsight-app-c0ec1ecd-3c27-4dd1-b13d-3c42c9c12adb {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

// hide mini sab
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail,
&.accommodation-search-book {
	.elfsight-app-c0ec1ecd-3c27-4dd1-b13d-3c42c9c12adb {
        display: block;
    }
    
}