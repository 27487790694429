// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 30px 0 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			flex-direction: column;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		.scroll-to-content {
			margin-bottom: 1rem;
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

&.accommodation-detail {
	.lead-section {
		.container-one-column {
			.container-holder {
				max-width: unset;
				margin: 0 -15px;
				text-align: unset;
			}
		}

	}
}


// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// wave-section
// =========================================================================
.wave-section {
	position: relative;
	padding-bottom: 6vh;
	background-color: $blue-lighter;
	//overflow: hidden;

	&::before {
		content: url('/images/large-wave-right.svg');
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		font-size: 0;
		line-height: 0;
		background-color: $blue-lightest;
	}

	// icon-collection
	.icon-collection {
		margin-bottom: 6vh;

		&.grid {
			.grid-items {
				margin-bottom: 0;

				.item {
					@include media-breakpoint-down(xs) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						flex: 0 !important;
						max-width: none !important;
					}

					.card {
						min-width: 130px;
						background: none;
						border: none;
						border-radius: 0;

						.card-image {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100px;
							height: 100px;
							margin: 0 auto 15px auto;
							background-color: $white;
							transition: 0.5;

							i {
								font-size: 36px;
							}
						}

						&[href]:hover {
							.card-image {
								background-color: $blue-light;
							}
						}

						.card-body {
							padding: 0;
							text-align: center;

							.card-caption {
								.card-title {
									margin-bottom: 0;
									font-size: 14px;
									font-weight: 700;
									font-family: $font-family-primary;
								}

								.card-subtitle {
									margin: 0;
									font-size: 14px;
									font-family: $font-family-primary;
								}

								.card-description {
									font-size: 14px;
									font-weight: 400;

									p {
										margin-bottom: 0;
									}
								}
							}
						}

						&[href]:hover {
							transform: scale(1.1);
						}
					}

					&:nth-child(1) {
						.card {
							.card-image {
								border-radius: 30% 70% 50% 50% / 50% 50% 50% 50%;
							}
						}
					}

					&:nth-child(2) {
						.card {
							.card-image {
								border-radius: 52% 48% 69% 31% / 40% 28% 72% 60%;
							}
						}
					}

					&:nth-child(3) {
						.card {
							.card-image {
								border-radius: 52% 48% 50% 50% / 67% 67% 33% 33%;
							}
						}
					}

					&:nth-child(4) {
						.card {
							.card-image {
								border-radius: 75% 25% 50% 50% / 61% 50% 50% 39%;
							}
						}
					}

					&:nth-child(5) {
						.card {
							.card-image {
								border-radius: 39% 61% 72% 28% / 38% 38% 62% 62%;
							}
						}
					}
				}
			}
		}
	}

	// large-collection
	.large-collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}

		.card {
			@extend .default-card;

			.card-body {
				padding: 10px 30px 35px 30px;

				.card-caption {
					.card-title {
						@include font-size($h2-font-size);
					}

					.card-subtitle {
						@include font-size($h3-font-size);
					}
				}

				.card-buttons {
					.card-btn {
						@extend .btn;
						@extend .btn-primary;
						padding: 0.375rem 0.75rem !important;

						&::before {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	height: 80vh;
	min-height: 300px;
	max-height: 800px;

	// image-collection
	.image-collection {
		&.owl-carousel {
			min-height: auto !important; // force

			// item
			.item {
				height: 80vh !important; // force
				min-height: 300px;
				max-height: 800px;
				padding: 0 !important; // force

				&::before {
					content: url('/images/wave-bottom.svg');
					z-index: 90;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					font-size: 0;
					line-height: 0;
				}

				&::after {
					content: url('/images/wave-top.svg');
					z-index: 90;
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					margin: 0 auto;
					font-size: 0;
					line-height: 0;
				}

				.owl-caption-holder {
					top: auto;

					.owl-caption {
						position: relative;
						max-width: 445px;
						padding: 45px 25px;
						border-radius: 6px;
						background-color: $blue;
						box-shadow: $shadow;
						text-shadow: none;

						@include media-breakpoint-down(md) {
							padding: 30px 20px;

						}

						&::after {
							content: '';
							position: absolute;
							top: -100px;
							right: 0;
							width: 149px;
							height: 166px;
							background: url('/images/fish-visual.svg');
							background-size: cover;

							@include media-breakpoint-down(md) {
								top: -75px;
								right: -30px;
								width: 125px;
								height: 139px;
							}
						}

						.owl-title {
							@include font-size($h3-font-size);
						}

						.owl-subtitle {
							font-size: 16px;
							font-weight: 700;
							font-family: $font-family-primary;
						}

						.owl-description {
							margin-bottom: 1rem;
							font-size: 14px;
						}

						.owl-btn {
							background-color: transparent;
							border-color: $white;

							&::before {
								content: '\f138';
								margin-right: 8px;
								font-weight: 300;
								font-family: $font-awesome;
							}

							&:hover {
								background-color: $blue-dark;
							}
						}
					}
				}
			}

			// owl-dots
			.owl-dots {
				display: none;
			}
		}
	}

	&.two {
		.image-collection {
			&.owl-carousel {
				.item {
					&::before {
						content: url('/images/wave-bottom-light.svg');
					}

					&::after {
						content: url('/images/wave-top-light.svg');
					}
				}
			}
		}
	}
}

// experience-section
// =========================================================================
.experience-section {
	position: relative;
	padding: 6vh 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		background-color: $blue-lighter;
	}

	&::after {
		content: url('/images/large-wave-left.svg');
		z-index: -1;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background-color: $blue-lighter;
	}

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// location-section
// =========================================================================
.location-section {
	margin: 6vh 0;

	.container-two-columns {
		.container-holder {
			justify-content: center;

			.column {
				&.one {
					@include media-breakpoint-up(md) {
						margin-right: -70px;
						padding-right: 15px !important;
					}
				}

				&.two {
					@include media-breakpoint-up(md) {
						margin-left: -70px;
						padding-left: 15px !important;
					}
				}
			}
		}
	}

	.wysiwyg {
		height: 100%;
		padding: 15px;
		border-radius: 6px;
		background-color: $blue-light;

		@include media-breakpoint-only(lg) {
			padding: 30px;
		}

		@include media-breakpoint-up(xl) {
			padding: 45px;
		}
	}

	.image {
		position: relative;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		&::before {
			content: '';
			position: absolute;
			top: 26.5%;
			left: 24%;
			width: 0;
			height: 0;

			@include media-breakpoint-down(sm) {
				top: -15px;
				left: 44%;
				border-top: 130px solid $blue-light;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
			}

			@include media-breakpoint-up(md) {
				top: 26.5%;
				left: 24%;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				border-left: 100px solid $blue-light;
			}
		}

		&::after {
			content: '';
			position: absolute;
			top: 29%;
			left: 43%;
			width: 16px;
			height: 16px;
			border-radius: 100%;
			background-color: $red;
			border: 3px solid $blue-lightest;

			@include media-breakpoint-down(sm) {
				top: 29%;
				left: 47%;
			}

			@include media-breakpoint-up(md) {
				top: 29%;
				left: 43%;
			}
		}
	}
}

// offcanvas-collection-section
// =========================================================================
.offcanvas-collection-section {
	margin: 6vh 0;
	overflow: hidden;

	.container {
		.container-holder {
			.column {
				padding-right: 0;
			}
		}
	}

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}

	// collection > slider
	.collection {
		&.slider {
			width: 100vw;

			.owl-stage {
				padding: 12px 0 12px 10px !important;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}


// accommodation-detail
// =========================================================================
&.accommodation-detail {
	.main {
		display: flex;
		flex-direction: column;

		.content-section {
			order: 4;
			margin-top: 0;
		}
	}
}
