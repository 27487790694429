// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;
	background: $white;
	box-shadow: $shadow;

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				position: static;
				padding-top: 10px;
				padding-bottom: 10px;
				align-items: center;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;

				@include media-breakpoint-up(xl) {
					padding-right: 30px;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			display: inline-flex;
			align-items: center;
			height: 34px;
			margin-right: 10px;
			padding: 0.5rem 1rem 0.25rem 1rem;
			border: none;
			background: $blue-dark;
			border-radius: 4px;
			color: $white;
			font-size: 15px;
			font-weight: 700;
			font-family: $font-family-secondary;
			transition: 0.5s;

			@include media-breakpoint-down(xs) {
				margin-right: 1px;
			}

			i {
				margin-right: 10px;
				font-size: 14px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}

	// logo
	.logo {
		background-image: url('/images/logo-bg.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: left top;
		position: absolute;
		top: -3px;
		left: -7px;
		width: 160px;
		height: 95px;
		transition: 0.5s;

		@include media-breakpoint-up(lg) {
			position: fixed;
			width: 300px;
			height: 184px;
		}

		a {
			@extend .d-inline-block;
			margin-top: 10px;
			margin-left: 20px;

			@include media-breakpoint-up(lg) {
				margin-top: 22px;
				margin-left: 42px;

			}

			img {
				transition: 0.5s;
				width: 89px;

				@include media-breakpoint-up(lg) {
					width: 160px;
				}
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 20px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $blue-dark;
						font-size: 15px;
						font-weight: 700;
						font-family: $font-family-secondary;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 14px;

							.nav-link {
								color: $blue-dark;

								&:hover {
									color: $blue;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				margin-right: 15px;

				>.nav-item {
					margin-left: 10px;
					font-size: 15px;
					font-weight: 700;
					font-family: $font-family-secondary;

					>.nav-link {
						padding-top: 0.75rem;
						padding-bottom: 0.25rem;
						color: $blue-dark;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border-color: $blue-dark;
						border-radius: 4px;
						box-shadow: $shadow;

						&::before {
							border-bottom-color: $blue-dark;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;
							font-family: $font-family-primary;

							.nav-link {
								color: $black;

								&:hover {
									color: $blue;
								}
							}

							&.active {
								.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		ul {
			li {
				+li {
					margin-left: 10px;

					@include media-breakpoint-down(xs) {
						margin-left: 1px;
					}
				}

				a {
					@extend .btn;
					@extend .btn-primary;
					min-width: 34px !important;
					height: 34px;
					padding: 0.5rem 0.75rem 0.25rem 0.75rem !important;
					font-size: 15px !important;
					font-family: $font-family-secondary;

					@include media-breakpoint-down(xs) {
						padding: 0.5rem 0 0.25rem 0 !important;
					}
				}

				&:first-of-type {
					a {
						@include media-breakpoint-down(xs) {
							.list-item-title {
								display: none;
							}
						}

						@include media-breakpoint-up(sm) {
							i {
								display: none;
							}
						}
					}
				}

				&:last-of-type {
					a {
						.list-item-title {
							display: none;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		@include media-breakpoint-down(xs) {
			margin-left: 5px;
		}

		@include media-breakpoint-up(lg) {
			margin-left: 20px;
		}

		.navbar-nav {
			&.language {
				.nav-item {
					.nav-link {
						display: inline-flex;
						align-items: center;
						border-radius: 0;
						border: none;
						color: $blue-dark;
						font-size: 14px;
						font-weight: 600;
						text-transform: uppercase;
						font-family: $font-family-secondary;

						&::after {
							content: '\f0ac';
							margin-left: 5px;
							border: none;
							font-weight: 900;
							font-family: $font-awesome;
						}

						&:hover {
							box-shadow: none;
							color: $blue;
						}
					}

					.dropdown-menu {
						background-color: $blue-dark;
						color: $white;
						font-size: 12px;
						border-radius: 6px;
						box-shadow: $shadow;
						overflow: hidden;

						.dropdown-item {
							color: $white;

							&:hover {
								color: $blue-dark;
							}
						}
					}
				}
			}
		}
	}
}

&.sticky,
&.navbar-collapse-active {
	.header {

		// logo
		.logo {
			@include media-breakpoint-down(lg) {
				width: 120px;
				height: 74px;

				a {
					margin-top: 10px;
					margin-left: 17px;

					img {
						width: 66px;
					}
				}
			}
		}
	}
}

&.accommodation-search-book,
&.accommodation-book {
	.logo,
	.header {
		position: absolute !important;
	}
}
