// footer
.footer {
	position: relative;
	margin-top: 197px;
	padding: 104px 0 30px 0;
	background-color: $blue;
	color: $white;
	font-size: 14px;

	&::before {
		content: '';
		position: absolute;
		top: -123px;
		left: 0;
		right: 0;
		width: 100%;
		height: 197px;
		background: url('/images/skyline.svg') no-repeat center;
		background-size: 1307px 197px;

		@include media-breakpoint-down(lg) {
			background-repeat: repeat-x;
			animation: skyline 60s linear infinite;
		}
	}

	@keyframes skyline {
		0% {
			background-position: 0;
		}

		100% {
			background-position: 1307px;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	a {
		color: $white;
		text-decoration: none;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.btn {
		background-color: $white;
		border-color: $white;
		color: $blue-dark;

		&:hover {
			background-color: $blue-dark !important;
			border-color: $blue-dark !important;
			color: $white !important;
		}

		@include media-breakpoint-down(xs) {
			font-size: 12px !important;
		}
	}

	// footer-outro
	.footer-outro {
		margin-bottom: 6vh;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		// footer-link
		.footer-link {
			.list {
				@include media-breakpoint-down(sm) {
					justify-content: center;
				}

				.list-item {
					margin: 0 10px 10px 0;

					.link {
						@extend .btn;
					}
				}
			}
		}

		// footer-logo
		.footer-logo {
			max-width: 160px;

			@include media-breakpoint-up(md) {
				margin-left: auto;
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		margin-bottom: 6vh;

		.column {
			@include media-breakpoint-down(xs) {
				@include make-col(6);
				font-size: 12px;

				h5 {
					font-size: 20px;
				}
			}

			&.one {
				a {
					color: $blue-light;
					text-decoration: underline;

					&:hover {
						color: $white;
					}
				}
			}
		}

		// footer-text
		.footer-text {
			ul {
				li {
					margin-bottom: 6px;

					a {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			margin: 5px 0 15px 0;

			ul {
				align-items: center;

				li {
					margin: 0 5px 5px 0;

					a {
						display: block;
						width: 36px;
						height: 36px;
						background-color: $blue-light;
						border-radius: 100%;
						color: $blue-dark;
						font-size: 20px;
						line-height: 36px;
						text-align: center;

						&:hover {
							background-color: $blue-dark;
							color: $blue-light;
						}
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {

		// footer-text
		.footer-text {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					font-size: 12px;

					@include media-breakpoint-up(sm) {
						+li {
							&::before {
								content: '';
								margin: 0 10px;
							}
						}
					}

					a {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		// back-to-top
		a.back-to-top {
			display: block;
			width: 24px;
			height: 24px;
			color: $white;
			font-size: 24px;
			line-height: 1;
			text-decoration: none;

			@include media-breakpoint-down(sm) {
				margin: 0 auto 15px auto;
			}

			@include media-breakpoint-up(md) {
				position: absolute;
				right: 15px;
				bottom: 0;
			}

			&::before {
				content: '\f0aa';
				font-weight: 300;
				font-family: $font-awesome;
			}

			&:hover {
				color: $blue-dark;
			}
		}
	}
}
