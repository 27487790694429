// mini-sab
.mini-sab {
	.container {
		position: relative;
		max-width: 975px;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 0;
			padding: 30px 15px;
			border-radius: 6px;
			background: $white;
			box-shadow: 0 0 24px rgba($black, 0.15);

			@include media-breakpoint-down(md) {
				margin: 15px 15px 0 15px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: 0;
				right: 44px;
				bottom: 40px;
				padding: 15px 0;
			}

			@include media-breakpoint-up(xl) {
				right: 0;
			}

			&::after {
				content: '';
				position: absolute;
				top: -100px;
				right: -75px;
				width: 149px;
				height: 166px;
				background: url('/images/fish-visual.svg');
				background-size: cover;

				@include media-breakpoint-down(md) {
					z-index: -1;
					right: -30px;
					width: 125px;
					height: 139px;
				}
			}
		}

		.heading {
			@extend .h1;
			text-align: center;

			@include media-breakpoint-up(sm) {
				display: none;
			}
		}

		.btn-toggle-mini-sab {
			@extend .btn;
			@extend .btn-primary;
			display: block;
			width: max-content;
			margin: 2rem auto 0;
		}

		// heading
		/*.heading {
			color: $white;
			font-size: 48px;
			font-weight: 600;
			font-family: $font-family-secondary;
		}*/
	}
}
