// btn
.btn {
	border-radius: 4px;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $blue;
			border-color: $blue;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}

	&.btn-large {
		padding: 0.75rem 1.5rem;
	}
}

// card-btn
.card-btn {
	display: inline-flex !important;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	text-decoration: none;

	&::before {
		content: '\f138';
		margin-right: 8px;
		font-size: 16px;
		font-weight: 300;
		font-family: $font-awesome;
	}

	&:hover {
		transform: translateX(5px);
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 20px;

	&::before {
		content: "\f137";
		position: absolute;
		left: 0;
		font-size: 16px;
		font-weight: 300;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;

	&::after {
		content: "\f13a";
		margin-left: 8px;
		font-size: 16px;
		font-weight: 400;
		font-family: $font-awesome;
	}
}
