.owl-carousel {
    &.slider {
        .owl-nav {
            --w3-nav-btn-pos: -15px;
            .owl-next {
                @include media-breakpoint-down(sm) {
                    right: var(--w3-nav-btn-pos);
                }
            }

            .owl-prev {
                @include media-breakpoint-down(sm) {
                    left: var(--w3-nav-btn-pos);
                }
            }
        }
    }
}