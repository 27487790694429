// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 960px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// headings
h4,
.h4 {
	font-size: 30px;
	font-family: $font-family-tertiary;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}


// image-collection
.image-collection {
	&.owl-carousel {
		.item {
			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				z-index: 100;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			box-shadow: $shadow;
		}
	}
}

// default-card
.default-card {
	border: none;
	border-radius: 6px;
	box-shadow: $shadow;

	.card-body {
		position: relative;

		&::before {
			content: url('/images/card-wave-white.svg');
			position: absolute;
			left: -5px;
			right: -5px;
			bottom: 99%;
			box-shadow: 0 0 0 #fff;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
		}

		padding: 10px 20px 30px 20px;

		.card-caption {
			display: flex;
			flex-direction: column;

			.card-title-link {
				margin-bottom: 0.25rem;
			}

			.card-subtitle {
				order: -1;
				margin: 0 0 0.25rem 0;

				@include media-breakpoint-up(xl) {
					font-size: 26px;
				}
			}

			.card-description {
				.list {
					color: $purple;
					font-size: 14px;
				}
			}
		}
	}

	&.card-arrangement {
		.card-image {
			.card-label {
				z-index: 1;
				bottom: 0;
				font-size: 14px;
			}
		}
		.card-body {
			.card-text {
				margin-bottom: 1rem;
				font-size: 14px;
			}

			.card-info {
				.card-staydata {
					display: block;
					margin-bottom: 1rem;

					li {
						font-size: 14px;
						line-height: 1.5;

						&::before {
							display: none;
						}
					}
				}

				.card-prices {
					padding: 10px;
					border-radius: 4px;
					background-color: $blue-light;
					color: $blue-dark;
				}
			}
		}
	}
}

// home-jump-to-link
.home-jump-to-link {
	position: absolute;
	top: -75px;
}

// owl-dots
.owl-carousel {
	.owl-dots {
		.owl-dot {
			span {
				width: 40px;
				height: 8px;
				margin: 2px;
				background: rgba($purple, 0.5);
				border-radius: 2px;
				border: none;
				box-shadow: none;
			}

			&:hover,
			&.active {
				span {
					background: $purple;
				}
			}
		}
	}
}
